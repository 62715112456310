
import emailjs from '@emailjs/browser';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Icon } from '@iconify/react';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import CloseButton from 'react-bootstrap/CloseButton';

import "./ContactUs.css"
import { useRef } from 'react';
import { Container } from 'react-bootstrap';
const ContactUs = (props) => {

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_wbgv5ft', 'template_1k7tvgt', form.current, 'RA9CGbF71JiWqW76a')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        // setShowThanks(true)
        // setShowReg(false)
        alert("email send")
    };
    const close = () => {
        return props.show === 0
    }
    //  
    return (

        <section>

            <Modal
                className='contact_us'
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <div className='border-0'>
                    <Modal.Header className="modal-header border-0">
                    </Modal.Header>


                </div>

                <div className='side'>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h1 >Get in touch
                            <p />with me today</h1>

                    </Modal.Title>


                    <h3>I’m interested in freelance opportunities – especially ambitious or large projects. However, if you have other request or question, don’t hesitate to use the form. </h3>
                    <li> <Icon icon="akar-icons:phone" /><i className="phone" /> +972 (0) 549174070</li>
                    <span className="phone" />
                    <li><Icon icon="ant-design:mail-outlined" /><i className="email" /> oshri@oshriwd.com</li>
                </div>
                <Modal.Body>

                    <form id="contact_form" name="contact_form" ref={form} onSubmit={sendEmail}>
                        <div class="mb-5 row">
                            <div class="col">
                                <label>First Name</label>
                                <input type="text" required maxlength="50" class="form-control" id="first_name" name="first_name" />
                            </div>
                            <div class="col">
                                <label>Last Name</label>
                                <input type="text" required maxlength="50" class="form-control" id="last_name" name="last_name" />
                            </div>
                        </div>
                        <div class="mb-5 row">
                            <div class="col">
                                <label for="email_addr">Email address</label>
                                <input type="email" required maxlength="50" class="form-control" id="email_addr" name="email"
                                    placeholder="name@example.com" />
                            </div>
                            <div class="col">
                                <label for="phone_input">Phone</label>
                                <input type="tel" required maxlength="50" class="form-control" id="phone_input" name="Phone"
                                    placeholder="Phone" />
                            </div>
                        </div>
                        <div class="mb-5">
                            <label for="message">Message</label>
                            <textarea class="form-control" id="message" name="message" rows="5"></textarea>
                        </div>
                        <div className='bu'>
                            <button type="submit" class="btn btn-primary px-4 btn-lg">Send</button>
                        </div>
                    </form>

                </Modal.Body>

            </Modal>
        </section >


    )
}

export default ContactUs