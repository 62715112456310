import React, { useEffect, useState } from "react";
import "./AboutMe.css";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";

import ArrowSkilles from "../../components/arrow/ArrowSkilles";
import AnimatedLetters from "../../components/animatedletters/AnimatedLetters";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngular,
  faCss3,
  faGitAlt,
  faHtml5,
  faJsSquare,
  faReact,
  faNodeJs,
  faBootstrap,
} from "@fortawesome/free-brands-svg-icons";
import ArrowAbout from "../../components/arrow/ArrowAbout";
const AboutMe = () => {
  return (
    <section className="section_aboutme">
      <div className="aboutme">
        <div className="pra ">
          <p id="tag">&lt;h1&gt; </p>

          <h1>About Me</h1>
          <p id="tag">&lt;/h1&gt; </p>
          <p>
            <p id="tag">&lt;p&gt; </p>
            <p>
              I'm a Full Stack Developer from Israel.
              <br />I have a great passion for UI design and creating new and
              interesting things.
            </p>
            <br />

            <p>
              {" "}
              In addition, I am involved in photography, it really connects me
              to Full Stack and the visuality of the Internet sites and
              creativity.
            </p>
            <br />

            <span style={{ color: "#F9F871" }}>Let's do something special</span>
            <p id="tag">&lt;/p&gt; </p>
          </p>
        </div>
        <div className="stage-cube-cont">
          <div className="cubespinner">
            <div className="face1">
              <FontAwesomeIcon icon={faBootstrap} color="#42D4CC" />
            </div>
            <div className="face2">
              <FontAwesomeIcon icon={faHtml5} color="#F06529" />
            </div>
            <div className="face3">
              <FontAwesomeIcon icon={faCss3} color="#28A4D9" />
            </div>
            <div className="face4">
              <FontAwesomeIcon icon={faReact} color="#5ED4F4" />
            </div>
            <div className="face5">
              <FontAwesomeIcon icon={faJsSquare} color="#EFD81D" />
            </div>
            <div className="face6">
              <FontAwesomeIcon icon={faNodeJs} color="green" />
            </div>
          </div>
        </div>
      </div>
      <ArrowAbout />
    </section>
  );
};

export default AboutMe;
