import React, { useEffect } from "react";
import ArrowHome from "../../components/arrow/ArrowHome";
import "./Home.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ContactUs from "../ContactUs/ContactUs";
import space from "./space.png";
const Home = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    var letters = document.getElementsByClassName("letter");

    function displayLetters() {
      for (var i = 0; i < letters.length; i++) {
        createTimer(i);
      }
    }

    function createTimer(index) {
      setTimeout(function () {
        letters[index].classList.add("letter-active");
      }, index * 50);
    }
    displayLetters();
  }, []);
  return (
    <div className="home">
      <header className="first_header">
        <div id="home_tag">&lt; h1 &gt;</div>

        <div className="letters-container">
          <div className="letters">
            <span className="letter">H</span>
            <span className="letter">I</span>
            <span className="letter">,</span>
          </div>
          <div className="letters">
            <span className="letter">I</span>
            <span className="letter">'</span>
            <span className="letter">m</span>
            <span id="space" />

            <span className="letter">O</span>
            <span className="letter">s</span>
            <span className="letter">h</span>
            <span className="letter">r</span>
            <span className="letter">i</span>
          </div>
             <div className="letters">
                           <span className="letter">A</span>
                         </div>
                  <span id="space" />

          <div className="letters">
            <span className="letter">W</span>
            <span className="letter">e</span>
            <span className="letter">b</span>
            <span id="space" />

            <span className="letter">D</span>
            <span className="letter">e</span>
            <span className="letter">v</span>
            <span className="letter">e</span>
            <span className="letter">l</span>
            <span className="letter">o</span>
            <span className="letter">p</span>
            <span className="letter">e</span>
            <span className="letter">r</span>
          </div>
          <div id="home_tag">&lt;/ h1 &gt;</div>
          <br />
          <div id="home_tag">&lt; h2 &gt;</div>

          <div className="letters profession">
            <span className="letter">F</span>
            <span className="letter">U</span>
            <span className="letter">L</span>
            <span className="letter">L</span>
            <span className="letter">- </span>
            <span className="letter">S</span>
            <span className="letter">T</span>
            <span className="letter">A</span>
            <span className="letter">C</span>
            <span className="letter">K</span>
            <span id="space" className="morespace" />
            <span className="letter">D</span>
            <span className="letter">E</span>
            <span className="letter">V</span>
            <span className="letter">E</span>
            <span className="letter">L</span>
            <span className="letter">O</span>
            <span className="letter">P</span>
            <span className="letter">M</span>
            <span className="letter">E</span>
            <span className="letter">N</span>
            <span className="letter">T</span>
          </div>
        </div>

        <div id="home_tag">&lt; button &gt;</div>
        <div className="login-box">
          <a onClick={() => setModalShow(true)} style={{ cursor: "pointer" }}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            Contact Me
          </a>
        </div>
        <div id="home_tag">&lt;/ button &gt;</div>
      </header>

      <div class="star-field">
        <div class="layer"></div>
        <div class="layer"></div>
        {/* 
                <div class="layer"></div> */}
      </div>
      <div className="shapes">
        <div className="rocket-holder">
          <img src={space} className="rocket" />
        </div>

        <div className="shape1 shape0" />
        <div className="shape2 shape0" />
        <div className="shape3 shape0" />
        <div className="shape4 shape0" />
        <div className="shape5 shape0" />
        <div>
          <img
            src="https://i.postimg.cc/vTpvkmxS/planet1.png"
            className="planet1 planets"
          />
          {/*
                    <img src="https://i.postimg.cc/HWK3V6v4/planet2.png" className="planet2 planets" />

                    <img src="https://i.postimg.cc/7ZrVNfk5/planet4.png" className="planet4 planets" />
                    <img src="https://i.postimg.cc/Twsj5P0Y/planet5.png" className="planet5 planets" />
                    <img src="https://i.postimg.cc/jS6h3txZ/planet6.png" className="planet6 planets" />
                    <img src="https://i.postimg.cc/wB6QSZ3H/planet7.png" className="planet7 planets" /> */}
          <img
            src="https://i.postimg.cc/HjDQ3PFq/planet8.png"
            className="planet8 planets"
          />
          <img
            src="https://i.postimg.cc/Jn0chXb2/planet9.png"
            className="planet9 planets"
          />

          <div className="final">
            <img
              src="https://i.postimg.cc/s2w4ymZW/human.png"
              className="human"
            />
            <img
              src="https://i.postimg.cc/h4J6yzHs/planet10.png"
              className="planet10"
            />

            <img
              src="https://i.postimg.cc/gcf5fcpW/planet11.png"
              className="planet11"
            />
          </div>
        </div>
      </div>
      <ContactUs show={modalShow} onHide={() => setModalShow(false)} />
      <div className="arrow_home">
        <ArrowHome />
      </div>
    </div>
  );
};

export default Home;
